.respond-section {
    position: relative;

    &::before {
        content: '';
        position: absolute;
        z-index: 1;
        background-color: var(--color-green-light);
        top: -80px;
        left: 0;
        width: 100%;
        height: 240px;
    }

    .respond-wrap {
        position: relative;
        z-index: 2;
        max-width: 580px;
        margin: 0 auto;

        h1 {
            margin-bottom: 30px;
            text-align: center;
        }

        .overzicht-project-tile {
            background-color: #fff;

            .overzicht-project-tile-wrap {
                .image {
                    &:hover figure {
                        transform: scale(1);
                    }
                }
            }

            .form-fields {
                border-top: 1px solid #efefef;
                padding: 16px 24px;
            }
        }
    }
}
