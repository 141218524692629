.aanbod-overzicht {
    .aanbod-overzicht-wrap {
        .aanbod-overzicht-filters {
            .filter-header {
                font-size: 1.2em;
                margin: 20px 0 8px;
            }
        }

        .pagination-wrap {
            display: flex;
            justify-content: center;

            .mdi-chevron-left,
            .mdi-chevron-right {
                pointer-events: none;
            }
        }
    }

    /*
    * Vue Loader
    */
    .loading-aanbod {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 400px;
        padding-bottom: 100px;

        .loading {
            display: flex;
            justify-content: center;

            div {
                width: 1rem;
                height: 1rem;
                margin: 2rem 0.3rem;
                background: var(--color-green-dark);
                border-radius: 50%;
                animation: 0.9s bounce infinite alternate;

                &:nth-child(2) {
                    animation-delay: 0.3s;
                }

                &:nth-child(3) {
                    animation-delay: 0.6s;
                }
            }
        }

        @keyframes bounce {
            to {
                opacity: 0.3;
                transform: translate3d(0, -1rem, 0);
            }
        }
    }

    .filters-selected {
        display: flex;
        flex-wrap: wrap;

        .filter-btn {
            cursor: pointer;
            border-radius: 3px;
            background-color: var(--color-green-light);
            padding: 5px 8px;
            line-height: 1.2;
            margin: 0 6px 6px 0;
            text-transform: capitalize;

            i {
                padding-right: 4px;
            }

            &:hover i {
                color: var(--color-red);
            }
        }
    }

    .aanbod-overzicht-head {
        display: flex;
        flex-direction: column;
        margin-bottom: 18px;

        .filter-aantal {
            margin: 0 0 18px 45px;
            background-color: #fff;
            padding: 12px 16px;
            border-radius: 5px;
            box-shadow: 0 4px 20px rgb(0 0 0 / 10%);
        }

        .filter-sort {
            display: flex;
            flex-direction: row;
            align-items: center;

            .sort-title {
                white-space: nowrap;
                margin: 0;
                padding-right: 12px;
            }

            .custom-select {
                margin: 0;
                min-width: 160px;
            }
        }
    }

    .custom-aanbod-table.v-data-table > .v-data-table__wrapper {
        overflow: visible;

        table {
            display: block;

            tbody {
                display: flex;
                flex-wrap: wrap;

                tr {
                    display: block;
                    width: 100%;

                    &:hover {
                        background-color: inherit !important;
                    }

                    td {
                        display: block;
                        padding: 0;
                        border: none !important;
                        font-size: inherit;
                        height: unset;

                        &.v-data-table__mobile-row {
                            min-height: unset;

                             .v-data-table__mobile-row__cell {
                                text-align: left;
                            }
                        }

                        &:nth-child(n+2) {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

/* mobile filter fix */
@media (--viewport-sm-max) {
    .aanbod-overzicht-filters {
        position: fixed;
        z-index: 10;
        top: 0;
        left: -320px;
        height: 100%;
        width: 320px;
        background-color: #fff;
        padding-top: 60px;
        transition: 0.2s;

        &.active {
            left: 0;
        }

        .aanbod-filters-toggle-relative {
            position: relative;
            height: 100%;

            .filters-overview {
                height: 100%;

                .filters {
                    height: 100%;
                    overflow: hidden;

                    .filters-scroll {
                        width: 100%;
                        height: 100%;
                        overflow: scroll;
                        padding: 30px 20px 100px;
                    }
                }
            }

            .filter-toggle {
                position: absolute;
                top: 30px;
                right: -49px;
                width: 49px;
                height: 49px;
                border-radius: 0 5px 5px 0;
                background-color: var(--color-green-dark);
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .icon {
                    width: 34px;
                }

                .text {
                    display: block;
                    line-height: 1;
                    font-size: 11px;
                    font-weight: 500;
                    color: #fff;
                    transform: translateY(-4px);
                }
            }
        }
    }

    .aanbod-overzicht-filters-background {
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        z-index: 9;
        background-color: #000;
        opacity: 0;
        height: 100%;
        width: 100%;
        transition: 0.2s;
        pointer-events: none;

        &.active {
            opacity: 0.6;
            pointer-events: auto;
        }
    }
}

@media (--viewport-md-min) {
    .aanbod-overzicht {
        .aanbod-overzicht-wrap {
            display: flex;
            justify-content: space-between;

            .aanbod-overzicht-filters {
                width: 26%;

                .filter-toggle {
                    display: none;
                }
            }

            .aanbod-overzicht-woningen {
                width: 71%;
            }
        }

        .aanbod-overzicht-head {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            background-color: #fff;
            padding: 4px 4px 4px 20px;
            border-radius: 5px;
            margin-bottom: 22px;
            box-shadow: 0 4px 20px rgb(0 0 0 / 10%);

            .filter-aantal {
                margin: 0;
                padding: 0;
                box-shadow: none;
            }

            .filter-sort {
                .sort-title {
                    padding-right: 0;
                }

                .custom-select {
                    background-color: #fff;
                }
            }
        }

        .custom-aanbod-table.v-data-table > .v-data-table__wrapper {
            table {
                tbody {
                    tr {
                        width: 50%;
                        padding: 0 10px 0 0;

                        &:nth-child(even) {
                            padding: 0 0 0 10px;
                        }
                    }
                }
            }
        }
    }
}
