.pswp__caption {
    display: none;
}

.woning-detail-collage {
    margin-top: 30px;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        z-index: 1;
        background-color: var(--color-green-light);
        top: -80px;
        left: 0;
        width: 100%;
        height: 240px;
    }

    .woning-detail-collage-wrap {
        position: relative;
        z-index: 2;
        margin: 0 -5px;

        .wdc-collage-grid {
            display: grid;
            grid-template-columns: repeat(3, 33.3333333%);
            grid-template-rows: 250px 90px;

            .wdc-media {
                position: relative;
                height: 100%;
                padding: 5px;

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    border-radius: 5px;
                }

                &:first-child {
                    grid-column-start: 1;
                    grid-column-end: 4;
                    grid-row-start: 1;
                    grid-row-end: 2;
                }

                &:nth-child(2) {
                    grid-column-start: 1;
                    grid-column-end: 2;
                    grid-row-start: 2;
                    grid-row-end: 3;
                }
            }
        }
    }
}

@media (--viewport-ms-min) {
    .woning-detail-collage .woning-detail-collage-wrap .wdc-collage-grid {
        grid-template-rows: 390px 110px;
    }
}

@media (--viewport-sm-min) {
    .woning-detail-collage .woning-detail-collage-wrap .wdc-collage-grid {
        grid-template-rows: 480px 160px;
    }
}

@media (--viewport-sm-max) {
    .woning-detail-collage .woning-detail-collage-wrap .wdc-collage-grid {

        &.moreDesktop .wdc-media:nth-child(3) .wdcOverlay{
            display: none;
        }

        &.moreMobile .wdc-media:nth-child(4) .wdcOverlay{
            position: absolute;
            top: 5px;
            left: 5px;
            width: calc(100% - 10px);
            height: calc(100% - 10px);
            background-color: rgb(0 0 0 / 71%);
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 32px;
            font-weight: 700;
            cursor: pointer;
            border-radius: 5px;
        }

        .wdc-media {

            &:nth-child(n+5) {
                visibility: hidden;
                position: fixed;
                width: 0;
                height: 0;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
}

@media (--viewport-md-min) {
    .woning-detail-collage {
        margin-top: 60px;

        .woning-detail-collage-wrap {

            .wdc-collage-grid {
                grid-template-columns: 66% 1fr 1fr;
                grid-template-rows: repeat(4, 130px);

                &.moreDesktop .wdc-media:nth-child(3) .wdcOverlay{
                    position: absolute;
                    top: 5px;
                    left: 5px;
                    width: calc(100% - 10px);
                    height: calc(100% - 10px);
                    background-color: rgb(0 0 0 / 71%);
                    color: #fff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 32px;
                    font-weight: 700;
                    cursor: pointer;
                    border-radius: 5px;
                }

                &.moreDesktop .wdc-media:nth-child(4) .wdcOverlay{
                    display: none;
                }

                .wdc-media {

                    &:first-child {
                        grid-column-start: 1;
                        grid-column-end: 2;
                        grid-row-start: 1;
                        grid-row-end: 5;
                    }

                    &:nth-child(2) {
                        grid-column-start: 2;
                        grid-column-end: 4;
                        grid-row-start: 1;
                        grid-row-end: 3;
                    }

                    &:nth-child(3) {
                        grid-column-start: 2;
                        grid-column-end: 4;
                        grid-row-start: 3;
                        grid-row-end: 5;
                    }

                    &:nth-child(n+4) {
                        visibility: hidden;
                        position: fixed;
                        width: 0;
                        height: 0;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
            }
        }
    }
}

@media (--viewport-lg-min) {
    .woning-detail-collage .woning-detail-collage-wrap .wdc-collage-grid {
        grid-template-rows: repeat(4, 140px);
    }
}

