.page-title {
    margin: 40px 0;

    .content {

        h1 {
            font-size: 2em;
            margin: 0;
        }
    }
}

@media (--viewport-md-min) {
    .page-title {
        margin: 70px 0;

        .content {

            h1 {
                font-size: 3em;
            }
        }
    }
}
