.home-filter {
    margin: 0;

    .home-filter-wrap {
        position: relative;
        z-index: 3;
        margin-top: -50px;
        padding: 20px 18px;
        background-color: #fff;
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
        border-radius: 5px;

        .home-filter-form-wrap {
            .zoek {
                .custom-submit input {
                    margin: 0;
                    line-height: 1.15;
                    padding: 12px 24px;
                }
            }
        }
    }
}

@media (--viewport-sm-min) {
    .home-filter {
        .home-filter-wrap {
            .home-filter-form-wrap {
                margin: 0 -7px;
                display: flex;
                flex-wrap: wrap;

                .custom-select {
                    margin: 0;
                }

                .plaats {
                    padding: 0 7px 8px;
                    width: 50%;
                }

                .object {
                    padding: 0 7px 8px;
                    width: 50%;
                }

                .prijs-min {
                    padding: 0 7px 8px;
                    width: 50%;
                }

                .prijs-max {
                    padding: 0 7px 8px;
                    width: 50%;
                }

                .zoek {
                    padding: 0 7px;
                }
            }
        }
    }
}

@media (--viewport-md-min) {
    .home-filter {

        .home-filter-wrap {
            padding: 25px 27px;

            .home-filter-form-wrap {
                flex-wrap: nowrap;

                .custom-select {
                    margin: 0;
                }

                .plaats {
                    padding: 0 7px;
                    width: 30%;
                }

                .object {
                    padding: 0 7px;
                    width: 22%;
                }

                .prijs-min {
                    padding: 0 7px;
                    width: 22%;
                }

                .prijs-max {
                    padding: 0 7px;
                    width: 22%;
                }

                .zoek {
                    padding: 0 7px;
                }
            }
        }
    }
}
