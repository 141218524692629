
.header {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 100;
    background: #fff;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);

    .header-wrap {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 14px 0;

        a {
            color: inherit;
            text-decoration: none;
            transition: 0.2s;

            &:hover {
                color: var(--color-green-dark);

                path {
                    fill: var(--color-green-dark);
                }
            }
        }
    }
}

.header-logo {
    align-self: center;

    img {
        display: block;
        height: 23px;
        width: auto;
    }
}

.header-links {
    line-height: 1.2;
    font-weight: 600;
    display: flex;
    align-items: center;

    .quick-nav {
        display: none;
    }

    ul {
        display: flex;
        align-items: center;
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            margin-left: 20px;

            .hamburger-menu {

                .header-icon {

                    svg {
                        width: 24px;
                        height: 24px;
                    }

                    .text {
                        display: none;
                    }
                }
            }

            .header-icon {
                position: relative;
                display: flex;
                align-items: center;

                .header-icon-notifcation {
                    position: absolute;
                    left: -6px;
                    top: -5px;
                    width: 16px;
                    height: 16px;
                    font-size: 10px;
                    border-radius: 100%;
                    background-color: var(--color-red);
                    color: #fff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                svg {
                    width: 18px;
                    height: 18px;
                }

                .text {
                    margin-left: 4px;
                }
            }
        }
    }
}

.header-fix {
    height: 52px;
}

.header-not-logged-in-overlay {
    position: fixed;
    z-index: 322;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    justify-content: center;
    align-items: center;

    &.active {
        display: flex;
    }

    .headernlio-background {
        position: fixed;
        z-index: 121;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.6);
        cursor: pointer;

        &.alt {
            background-color: transparent;
        }
    }

    .headernlio-wrap {
        position: relative;
        z-index: 123;
        margin: 15px;

        .headernlio-box {
            background-color: #fff;
            max-width: 460px;
            width: 100%;
            padding: 22px;
            border-radius: 5px;

            .close-button {
                position: absolute;
                z-index: 10;
                top: 12px;
                right: 12px;
                width: 30px;
                height: 30px;
                cursor: pointer;

                .close-button-wrap {
                    position: relative;

                    span {
                        position: absolute;
                        content: '';
                        display: block;
                        background-color: #000;
                        width: 26px;
                        height: 3px;
                        top: 14px;
                        left: 2px;
                    }

                    .aa {
                        transform: rotate(45deg);
                    }

                    .bb {
                        transform: rotate(-45deg);
                    }
                }
            }

            .title {
                margin: 8px 0 12px;
            }

            .buttons {

            }

            .form {

                h2 {
                    margin-bottom: 16px;
                }

                a {
                    font-weight: 700;
                    text-decoration: none;
                    color: var(--color-green-dark);

                    &:hover {
                        color: #406963;
                    }
                }

                .forgot-password {
                    margin-top: 20px;
                }

                .got-no-account {
                    margin-top: 30px;
                    padding-top: 20px;
                    border-top: 1px solid #efefef;
                }
            }
        }
    }
}

@media (max-width: 360px) {
    .header .inloggen-menu {
        display: none;
    }
}

@media (min-width: 360px) {
    .header-logo {
        img {
            height: 20px;
        }
    }
}

@media (min-width: 420px) {
    .header-logo {
        img {
            height: 25px;
        }
    }
}

@media (--viewport-md-min) {
    .header-logo {
        img {
            height: 32px;
        }
    }

    .header-links {
        .quick-nav {
            display: flex;
        }

        ul {
            li {
                margin-left: 30px;

                .hamburger-menu {
                    .header-icon {
                        .text {
                            display: block;
                        }
                    }
                }

                .header-icon {

                    svg {
                        width: 22px;
                        height: 22px;
                    }

                    .text {
                        margin-left: 4px;
                    }
                }
            }
        }
    }

    .header-fix {
        height: 60px;
    }
}
