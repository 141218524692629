/* Insert css here */
.vps-page-title {
    margin: 0;
    min-height: 200px;
    /* margin-top: calc(var(--grid-section-margin) * -4); */
    background-image: url('/dist/images/stock-img.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    .overlay {
        background-color: #000;
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 1;
        left: 0;
        right: 0;
        top: 0;
        opacity: 0.6;
    }

    .container {

        .content {
            position: relative;
            z-index: 2;
            color: #fff;
            max-width: 800px;
            margin: 70px auto 100px;

            h1 {
                font-size: 2em;
                color: #fff;
                text-align: center;
                margin: 0;
            }
        }
    }
}

.vps-benefits-title {
    position: relative;
    z-index: 3;
    margin-top: -40px;
    padding-left: 15px;
    padding-right: 15px;

    .vps-benefits-wrap {
        background-color: #fff;
        box-shadow: 0 0 20px #00000024;
        border-radius: 5px;
        padding: 30px 40px;
        width: 100%;
        max-width: 1400px;
        margin: 0 auto;

        ul {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            justify-content: space-between;
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                margin: 10px 0;
                width: 100%;
                font-weight: 600;
                position: relative;
                min-height: 40px;
                padding-left: 60px;

                img {
                    position: absolute;
                    height: auto;
                    width: 36px;
                    left: 0;
                    top: 0;
                }
            }
        }
    }
}

.vps-intro {

    .vps-intro-wrap {
        max-width: 800px;
        margin: 0 auto;
    }
}

.vps-how {
    background-color: #efefef;
    padding: 40px 0;

    .vps-how-wrap {
        width: 100%;
        max-width: 1400px;
        margin: 0 auto;
        padding-right: 20px;
        padding-left: 20px;

        .vps-how-intro {
            text-align: center;
            margin-bottom: 40px;
        }

        ul {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            justify-content: space-between;
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                position: relative;
                margin: 0 20px 40px;
                flex: 1 0 26%;
                text-align: center;

                img {
                    /* position: absolute; */
                    display: block;
                    width: 72px;
                    margin: 0 auto 20px;
                }

                h3 {
                    font-size: 1.1em;
                    font-weight: 700;
                }
            }
        }
    }
}

.vps-testimonials {

    h2 {
        text-align: center;
    }

    .module-quote {
        margin: 0 0 40px 0;

        .quote-carousel {

            .carousel-cell {
                width: 100%;
                padding: 0 8px 40px;

                .quote-content {
                    max-width: 800px;
                    margin: 0 auto;
                    padding: 0 30px;
                    text-align: center;
                    /* font-weight: 700; */

                    p {
                        font-size: 1em;
                    }

                    span {
                        text-transform: uppercase;
                        color: var(--color-red);
                    }
                }
            }
        }

        .flickity-viewport {
            transition: height 0.3s;
        }

        .flickity-prev-next-button {
            display: block;
            width: 26px;
            height: 26px;
            background: #60968e;
            transition: .2s;

            &.next {
                right: -10px;
            }

            &.previous {
                left: -10px;
            }

            .arrow {
                fill: white;
            }

            &.no-svg {
                color: white;
            }

            &:hover {
                background: var(--color-red);
            }

            &:disabled {
                display: none;
            }
        }
    }
}

.vps-benefits {
    position: relative;
    z-index: 3;
    padding: 0 15px;

    .vps-benefits-wrap {
        padding: 0x;
        width: 100%;
        max-width: 1400px;
        margin: 0 auto;

        h2 {
            text-align: center;
        }

        ul {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            justify-content: space-between;
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                margin: 0 0 20px;
                padding: 40px 30px;
                width: 100%;
                border-radius: 5px;
                background-color: #fff;
                box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

                img {
                    height: 42px;
                    width: auto;
                    margin: 0;
                }

                h3 {
                    font-size: 1em;
                    font-weight: 700;
                }
            }
        }
    }
}

.vps-cta {
    position: relative;
    overflow: hidden;
    /* background-color: var(--color-green); */
    margin-bottom: 0;
    padding: 0 0 40px 0;

    .vps-cta-background {
        position: absolute;
        z-index: 1;
        top: 300px;
        height: calc(100% - 300px);
        width: 100%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .vps-cta-wrap {
        position: relative;
        z-index: 2;
        max-width: 800px;
        margin: 0 auto;

        .vps-cta-intro {
            /* color: #fff; */
        }

        .vps-cta-form {
            background-color: #fff;
            margin: 40px 0 0;
            padding: 30px;
            box-shadow: 0 0 20px #00000024;
            border-radius: 5px;

            form {

                input,
                textarea {

                }

                input[type=submit] {
                    width: auto;
                    padding: 15px 30px;
                }
            }
        }
    }
}

@media (--viewport-md-min) {
    .vps-page-title {
        min-height: 200px;
        /* margin-top: calc(var(--grid-section-margin) * -8); */

        .container {

            .content {
                margin: 160px auto 200px;

                h1 {
                    font-size: 2.7em;
                }
            }
        }
    }

    .vps-benefits-title {
        margin-top: -80px;

        .vps-benefits-wrap {
            padding: 40px;

            ul {
                flex-direction: row;

                li {
                    margin: 0 20px;
                    width: 21%;
                    text-align: center;
                    max-width: 240px;
                    min-height: auto;
                    padding-left: 0;

                    img {
                        position: unset;
                        height: 42px;
                        width: auto;
                        margin: 0 auto 20px;
                    }
                }
            }
        }
    }

    .vps-how {
        padding: 80px 0;

        .vps-how-wrap {

            .vps-how-intro {
                margin-bottom: 40px;
            }

            ul {
                flex-direction: row;

                li {
                    margin: 0 20px;
                    max-width: 400px;
                }
            }
        }
    }

    .vps-testimonials {

        .module-quote {

            .module-quote {

                .quote-carousel {

                    .carousel-cell {

                        .quote-content {

                            p {
                                font-size: 1.2em;
                            }
                        }
                    }
                }
            }

            .flickity-prev-next-button {
                width: 32px;
                height: 32px;
            }
        }
    }

    .vps-benefits {

        .vps-benefits-wrap {
            padding: 0 40px;

            ul {
                flex-direction: row;

                li {
                    margin: 20px 0;
                    padding: 40px;
                    width: 48.5%;
                }
            }
        }
    }

    .vps-cta {
        padding: 0 0 80px;

        .vps-cta-wrap {

            .vps-cta-form {
                margin: 40px 0 0;
                padding: 40px;
            }
        }
    }
}