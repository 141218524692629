.footer {
    background-color: var(--color-green-dark);

    .image img {
        display: block;
        width: 76px;
    }

    .footer-wrap {
        padding: 30px 0 40px;

        a {
            color: inherit;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }

        .list {
            color: #fff;
            margin-bottom: 30px;

            h3 {
                color: #fff;
                font-size: 1.3em;
            }

            ul {
                margin: 16px 0;
                list-style: none;
                padding: 0;

                li {
                    margin-bottom: 4px;
                }
            }
        }
    }
}

@media (--viewport-sm-min) {
    .footer {

        .footer-wrap {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            .list {
                width: 50%;
            }
        }
    }
}

@media (--viewport-md-min) {
    .footer {

        .footer-wrap {
            flex-wrap: nowrap;

            .list {
                width: unset;
                min-width: 20%;

                h3 {
                    font-size: 1.6em
                }
            }
        }
    }
}
