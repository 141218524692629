.srp-head {
    .container {
        max-width: 840px;
    }

    form {
        display: flex;

        .srp-input {
            background-image: url('/dist/images/searchicon.png');
            background-size: 20px 20px;
            background-position: 14px 12px;
            background-repeat: no-repeat;
            border-radius: 5px 0 0 5px;
            width: 100%;
            line-height: 1.2;
            padding: 12px 20px 12px 46px;
            margin-bottom: 0;
        }

        .srp-submit {
            border-radius: 0 5px 5px 0;
            margin: 0;
        }
    }

    .divider {
        display: block;
        margin-top: 2em;
        margin-bottom: 2em;
        margin-left: auto;
        margin-right: auto;
        border-bottom: 1px solid var(--color-gray);
    }
}

.srp-results {
    .container {
        max-width: 840px;
    }

    .srp-result-tile {
        .srp-rt-wrap {
            display: flex;
            padding-bottom: 20px;
            margin-bottom: 30px;
            border-bottom: 1px solid var(--color-gray);
            flex-direction: column;

            .image {
                width: 100px;
                margin: 0 0 20px 0;

                img {
                    display: block;
                    width: 100%;
                    object-fit: contain;
                }
            }

            .content {
                h2 {
                    margin-top: 0;
                    margin-bottom: 6px;

                    a {
                        color: var(--color-jess-black);
                        text-decoration: none;
                        transition: 0.2s;

                        &:hover {
                            color: var(--color-orange);
                        }
                    }
                }

                p {
                    margin-top: 6px;
                }
            }
        }
    }

    .srp-pagination {
        margin: 40px 0;
        display: flex;
        justify-content: center;
    }
}

@media (--viewport-md-min) {
    .srp-results {
        .srp-result-tile {
            .srp-rt-wrap {
                flex-direction: row;
                align-items: center;

                .image {
                    width: 140px;
                    min-width: 140px;
                    margin: 0 30px 0 0;

                    img {
                        width: 100%;
                        height: 140px;
                    }
                }
            }
        }
    }
}
