.contact-section {

    .contact-section-wrap {

        .info {
            margin-bottom: 40px;

            h2 {
                margin-bottom: 16px;
            }

            ul {
                list-style-type: none;
                margin: 0;
                padding: 0;

                li {
                    margin: 8px 0;
                }

                i {
                    width: 24px;
                    color: var(--color-green-dark);
                }

                a {
                    color: inherit;
                    text-decoration: none;

                    &:hover {
                        color: var(--color-green-dark);
                    }
                }
            }
        }

        .form {
            margin-bottom: 40px;

            p {
                margin-bottom: 24px;
            }
        }
    }
}

@media (--viewport-md-min) {
    .contact-section {

        .contact-section-wrap {
            display: flex;

            .info {
                width: 50%;
                margin-bottom: 0;

                h2 {
                    margin-bottom: 16px;
                }

                ul {
                    list-style-type: none;
                    margin: 0;
                    padding: 0;

                    li {
                        margin: 8px 0;
                    }

                    i {
                        width: 24px;
                        color: var(--color-green-dark);
                    }

                    a {
                        color: inherit;
                        text-decoration: none;

                        &:hover {
                            color: var(--color-green-dark);
                        }
                    }
                }
            }

            .form {
                width: 50%;
                margin-bottom: 0;
            }
        }
    }
}
