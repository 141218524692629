.hero {
    margin: 0;
    min-height: 380px;
    background-image: url('/dist/images/stock-img.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    .overlay {
        background-color: var(--color-secondary);
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 1;
        left: 0;
        right: 0;
        top: 0;
        opacity: 0.6;
    }

    .content {
        position: relative;
        z-index: 2;
        color: #fff;
        max-width: 800px;
        margin: 100px 0 80px;

        span {
            font-size: 1.2em;
            font-weight: 600;
        }

        h1 {
            font-size: 2em;
            color: #fff;
            margin: 16px 0;
        }
    }
}

@media (--viewport-md-min) {
    .hero {
        min-height: 550px;

        .content {
            margin: 100px 0 140px;

            span {
                font-size: 1.3em;
            }

            h1 {
                font-size: 3em;
            }
        }
    }
}
