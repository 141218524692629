@media (--viewport-sm-max) {
    .my-account-dashboard-menu {
        position: fixed;
        z-index: 10;
        top: 0;
        left: -320px;
        height: 100%;
        width: 320px;
        background-color: #fff;
        padding-top: 60px;
        transition: 0.2s;

        &.active {
            left: 0;
        }

        .my-account-dashboard-menu-wrap {
            height: 100%;
            overflow: hidden;

            .my-account-dashboard-menu-scroll {
                height: 100%;
                overflow: scroll;
                padding: 30px 20px;
            }
        }

        .my-account-dashboard-relative {
            position: relative;
            height: 100%;

            .my-account-menu-toggle {
                position: absolute;
                top: 22px;
                right: -49px;
                width: 49px;
                height: 49px;
                border-radius: 0 5px 5px 0;
                background-color: var(--color-green-dark);
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .icon {
                    width: 34px;
                }

                .text {
                    display: block;
                    line-height: 1;
                    font-size: 11px;
                    font-weight: 500;
                    color: #fff;
                    transform: translateY(-4px);
                }
            }
        }
    }

    .my-account-dashboard-menu-background {
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        z-index: 9;
        background-color: #000;
        opacity: 0;
        height: 100%;
        width: 100%;
        transition: 0.2s;
        pointer-events: none;

        &.active {
            opacity: 0.6;
            pointer-events: auto;
        }
    }
}

.my-account-dashboard {
    .my-account-dashboard-wrap {
        .my-account-dashboard-menu {
            .my-account-dashboard-menu-header {
                padding-bottom: 9px;
                margin-bottom: 16px;

                h1 {
                    font-size: 22px;
                    margin: 0;
                }
            }
        }

        .my-account-dashboard-view {
            .my-account-dashboard-menu-view {
                padding-bottom: 8px;
                border-bottom: 1px solid #dadada;
                margin: 0 0 16px 44px;

                h2 {
                    font-size: 22px;
                    margin: 0;
                }
            }

            .my-account-dashboard-menu-content {
                h2,
                h3,
                h4, {
                    margin: 16px 0 6px;
                }

                p {
                    margin: 6px 0 8px;
                }

                label {
                    font-weight: 700;
                    font-size: 1.1em;
                }
            }
        }
    }
}

.dashboard-overlay {
    position: fixed;
    z-index: 122;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .do-background {
        position: fixed;
        z-index: 121;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.6);
        cursor: pointer;

        &.alt {
            background-color: transparent;
        }
    }

    .do-wrap {
        position: relative;
        z-index: 123;
        margin: 15px;

        .do-box {
            background-color: #fff;
            max-width: 460px;
            width: 100%;
            padding: 22px;
            border-radius: 5px;

            .close-button {
                position: absolute;
                z-index: 10;
                top: 12px;
                right: 12px;
                width: 30px;
                height: 30px;
                cursor: pointer;

                .close-button-wrap {
                    position: relative;

                    span {
                        position: absolute;
                        content: '';
                        display: block;
                        background-color: #000;
                        width: 26px;
                        height: 3px;
                        top: 14px;
                        left: 2px;
                    }

                    .aa {
                        transform: rotate(45deg);
                    }

                    .bb {
                        transform: rotate(-45deg);
                    }
                }
            }

            .title {
                margin: 8px 0 12px;
            }

            .buttons {

            }

            .form {

                h2 {
                    margin-bottom: 12px;
                }

                a {
                    font-weight: 700;
                    text-decoration: none;
                    color: var(--color-green-dark);

                    &:hover {
                        color: #406963;
                    }
                }

                .forgot-password {
                    margin-top: 20px;
                }

                .got-no-account {
                    margin-top: 30px;
                    padding-top: 20px;
                    border-top: 1px solid #efefef;
                }
            }
        }
    }
}

@media (--viewport-md-min) {
    .my-account-dashboard {
        .my-account-dashboard-wrap {
            display: flex;

            .my-account-dashboard-menu {
                width: 100%;
                max-width: 380px;
                padding-right: 60px;

                .my-account-dashboard-menu-header {
                    h1 {
                        font-size: 24px;
                    }
                }
            }

            .my-account-dashboard-view {
                width: 100%;
                max-width: 600px;
                margin: 0 auto;

                .my-account-dashboard-menu-view {
                    margin: 0 0 16px 0;

                    h2 {
                        font-size: 24px;
                    }
                }
            }

            .my-account-dashboard-relative {
                .my-account-menu-toggle {
                    display: none;
                }
            }
        }
    }
}
