.woning-detail {

    .woning-detail-wrap {
        /* display: flex; */
        /* justify-content: space-between; */

        .wdw-main {
            /* width: 66%; */
        }

        .wdw-side {
            /* width: 33%; */
        }
    }
}

@media (--viewport-sm-max) {
    .wdw-side-wrap {

        .heading {
            display: none;
        }

        .buttons {

            .main-buttons {
                position: fixed;
                z-index: 3;
                bottom: 0;
                left: 0;
                width: 100%;
                display: flex;
                justify-content: space-between;
                background-color: #fff;
                padding: 8px;

                .bezichtiging {
                    width: 100%;
                    margin-right: 4px;
                }

                .inschrijven {
                    width: 100%;
                    margin-left: 4px;
                }

                .button {
                    width: 100%;
                }
            }

            .interesse {
                position: absolute;
                z-index: 3;
                top: 100px;
                right: 28px;
                cursor: pointer;

                .interest {
                    svg {
                        path {
                            stroke: #000;
                        }
                    }

                    &.interested {
                        svg path {
                            stroke: var(--color-red);
                        }
                    }
                }

                .button {
                    padding: 6px 8px;
                    width: 100%;
                    background-color: #fff;
                    border: #000 solid 2px;
                    color: #000;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    pointer-events: none;

                    .text {
                        margin-left: 4px;
                    }

                    &:focus .interest {
                        animation: beat 0.6s ease-in-out backwards;
                    }

                    &:active .interest {
                        animation: none;
                    }
                }
            }

            .button {
                font-size: 13px;
                margin: 0;
                padding: 9px;
            }
        }

        .roadmap {
            display: none;
        }
    }
}

.wdw-project-notification {
    margin-bottom: 16px;

    a {
        text-decoration: none;
        color: var(--color-green-dark);

        &:hover {
            color: #406963;
        }
    }

    .wdw-project-notification-wrap {
        padding: 8px 0;
        font-weight: 700;
        border-top: 1px solid #cdcdcd;
        border-bottom: 1px solid #cdcdcd;
    }
}

.wdw-main-wrap {

    .wdw-header {
        margin-bottom: 20px;
        padding-bottom: 12px;
        border-bottom: #efefef solid 1px;

        .name {
            font-size: 1.6em;
            margin-bottom: 12px;
        }

        .location {
            display: flex;
            align-items: center;
            color: var(--color-green-dark);

            img {
                display: block;
                width: 20px;
                height: 20px;
                margin-right: 6px;
            }
        }

        .price {
            font-size: 20px;
            color: #000;
            display: block;
            font-weight: 700;
            margin: 12px 0;
        }

        ul {
            margin: 24px 0;
            list-style: none;
            line-height: 1.2;
            /* display: flex; */

            li {
                margin: 0 32px 12px 0;

                &:last-child {
                    margin-bottom: 0;
                }

                .item-wrap {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;

                    img {
                        display: block;
                        width: 20px;
                        height: 20px;
                        margin-right: 6px;
                    }

                    .blck {
                        font-weight: 600;
                        color: black;
                    }
                }
            }
        }
    }

    .wdw-content {

        p {
            margin: 6px 0 12px;
        }
    }
}

.wdw-not-logged-in-overlay {
    position: fixed;
    z-index: 122;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .wdwnlio-background {
        position: fixed;
        z-index: 121;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.6);
        cursor: pointer;

        &.alt {
            background-color: transparent;
        }
    }

    .wdwnlio-wrap {
        position: relative;
        z-index: 123;
        margin: 15px;

        .wdwnlio-box {
            background-color: #fff;
            max-width: 460px;
            width: 100%;
            padding: 22px;
            border-radius: 5px;

            .close-button {
                position: absolute;
                z-index: 10;
                top: 12px;
                right: 12px;
                width: 30px;
                height: 30px;
                cursor: pointer;

                .close-button-wrap {
                    position: relative;

                    span {
                        position: absolute;
                        content: '';
                        display: block;
                        background-color: #000;
                        width: 26px;
                        height: 3px;
                        top: 14px;
                        left: 2px;
                    }

                    .aa {
                        transform: rotate(45deg);
                    }

                    .bb {
                        transform: rotate(-45deg);
                    }
                }
            }

            .title {
                margin: 8px 0 12px;
            }

            .buttons {

            }

            .form {

                h2 {
                    margin-bottom: 12px;
                }

                a {
                    font-weight: 700;
                    text-decoration: none;
                    color: var(--color-green-dark);

                    &:hover {
                        color: #406963;
                    }
                }

                .forgot-password {
                    margin-top: 20px;
                }

                .got-no-account {
                    margin-top: 30px;
                    padding-top: 20px;
                    border-top: 1px solid #efefef;
                }
            }
        }
    }
}

@media (--viewport-md-min) {
    .woning-detail {

        .woning-detail-wrap {
            display: flex;
            justify-content: space-between;

            .wdw-main {
                width: 66%;
            }

            .wdw-side {
                width: 33%;
            }
        }
    }

    .wdw-main-wrap {
        .wdw-header {
            ul {
                display: flex;
                flex-wrap: wrap;
            }
        }
    }

    .wdw-project-notification {
        transform: translateY(-12px);
    }

    .wdw-side-wrap {
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
        overflow: hidden;
        background-color: #fff;
        border-radius: 5px;

        .heading {
            background-color: #efefef;
            padding: 16px 12px;
        }

        .buttons {
            padding: 12px;

            .main-buttons {
                display: flex;
                justify-content: space-between;

                .bezichtiging {
                    margin-right: 4px;
                }

                .inschrijven {
                    margin-left: 4px;
                }
            }

            .interesse {
                cursor: pointer;

                .interest {
                    pointer-events: none;

                    svg {
                        path {
                            stroke: #000;
                        }
                    }

                    &.interested {
                        svg path {
                            stroke: var(--color-red);
                        }
                    }
                }

                .button {
                    padding: 10px 12px;
                    width: 100%;
                    background-color: #fff;
                    border: #000 solid 2px;
                    color: #000;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    pointer-events: none;

                    .text {
                        pointer-events: none;
                        margin-left: 4px;
                    }

                    &:hover {
                        background-color: #ecf5f1;
                    }

                    &:focus .interest {
                        animation: beat 0.6s ease-in-out backwards;
                    }

                    &:active .interest {
                        animation: none;
                    }
                }
            }

            .button {
                font-size: 14px;
                margin: 0 0 8px;
                padding: 14px;
            }
        }

        .roadmap {
            border-top: 1px solid #efefef;
            padding: 12px;
        }
    }
}
