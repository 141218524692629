/**
 * Base
 */
:root {
    --site-background-color: var(--site-background-color, rgb(255, 255, 255));
}

/* Box sizing */
* {
    box-sizing: border-box;

    &::before,
    &::after {
        box-sizing: border-box;
    }
}

/* Body */
body {
    background-color: var(--site-background-color);
    font-family: var(--base-font-family);
    font-weight: var(--base-font-weight);
    font-size: var(--base-font-size);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(--color-text);
    line-height: 1.5;
    overflow-x: hidden;
    position: relative;
    width: 100%;
    margin: 0;
    padding: 0;

    @media (--viewport-md-min) {
        font-size: 16px;
    }
}

/* Links */
a {
    color: var(--color-link, #0066cb);
    text-decoration: underline;
    text-decoration-skip-ink: auto;

    &:hover,
    &:focus {
        color: #006bff;
    }
}

/* Horizontal ruler */
hr {
    border: none;
    border-bottom: 1px solid var(--color-gray);
    margin: 30px 0;
}

/* Image handling */
figure {
    margin: 0;
    background-size: auto 100%;
    background-position: center center;
    background-repeat: no-repeat;

    img {
        max-width: 100%;
        width: 100%;
        height: auto;
        display: block;
        margin: 0 auto;
        transition: opacity 0.2s;
    }

    &.contain-width img {
        width: auto;
    }
}

/* Form fix for umbraco */
form fieldset {
    border: none;
    padding: 0;
}

/* Headings */
h1,
h2,
h3,
h4,
h5,
h6 {
    line-height: 1.3;
    font-weight: 600;
    color: var(--color-header-text);
    margin: 8px 0;
}

p {
    margin: 8px 0;
}

/* Background settings */
.background-section {
    padding: 40px 0;
    margin: 0;
}

@media (--viewport-md-min) {
    .background-section {
        padding: 80px 0;
    }
}

/* pagination */
.pagination {
    list-style-type: none;
    display: flex;
    font-weight: 400;
    padding: 0;

    li {
        display: block;
        padding: 0 4px;

        a {
            position: relative;
            display: inline-block;
            text-decoration: none;
            color: inherit;
            padding: 6px;

            &::after {
                content: '';
                position: absolute;
                display: block;
                background-color: var(--color-secondary);
                width: 0;
                height: 1px;
                left: 50%;
                bottom: -4px;
                transform: translateX(-50%);
                transition: 0.3s;
            }

            &.active,
            &:hover {
                &::after {
                    width: 20px;
                }
            }

            &.disabled {
                cursor: default;
                user-select: none;

                &:hover::after {
                    width: 0;
                }
            }
        }
    }
}

/*
* Interesse hartje icon
*/

.interest {
    /* display: flex;
    align-items: center; */
    cursor: pointer;
    transform: scale(1, 1);

    svg {
        display: block;

        path {
            fill: transparent;
            stroke: #6f6f6f;
            stroke-width: 2;
            transition: 0.2s;
        }
    }

    &.interested {
        svg path {
            fill: var(--color-red);
            stroke: var(--color-red);
        }
    }

    &:focus {
        animation: beat 0.6s ease-in-out backwards;
    }

    &:active {
        animation: none;
    }

    @keyframes beat {
        0% {
            transform: scale(1, 1);
        }

        33% {
            transform: scale(0.85, 0.85);
        }

        66% {
            transform: scale(1.2, 1.2);
        }

        100% {
            transform: scale(1, 1);
        }
    }
}

/* Vuetify Custom CSS */
[v-cloak] { display: none; }

/* Vuetify Font Family */
.v-application {
    font-family: 'Poppins', sans-serif;
}

/* Vuetify Input */
.front-end-input-style {
    .v-input {
        .v-input__slot {
            box-shadow: none !important;
            padding: 0 !important;
            min-height: unset !important;
            border: none !important;
        }

        .v-label {
            margin: 0;
            display: block;
        }

        input {
            margin: 0;
            padding: 12px !important;
            max-height: unset;
        }
    }
}


/*
* Vue Loader
*/
.loading-vue {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
    padding-bottom: 100px;

    .loading {
        display: flex;
        justify-content: center;

        div {
            width: 1rem;
            height: 1rem;
            margin: 2rem 0.3rem;
            background: var(--color-green-dark);
            border-radius: 50%;
            animation: 0.9s bounce infinite alternate;

            &:nth-child(2) {
                animation-delay: 0.3s;
            }

            &:nth-child(3) {
                animation-delay: 0.6s;
            }
        }
    }

    @keyframes bounce {
        to {
            opacity: 0.3;
            transform: translate3d(0, -1rem, 0);
        }
    }
}


/*
* Form fix vuetify
*/
form {

    .row {
        margin: 0 -10px;
    }

    .row+.row {
        margin-top: 0;
    }

    .col-xs-12,
    .col-md-3,
    .col-md-4,
    .col-md-6,
    .col-md-12 {
        padding: 0 10px;
    }
}
