.overzicht-project-tile {
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    margin-bottom: 16px;
    height: 100%;

    a {
        color: inherit;
        text-decoration: none;
        display: block;
        height: 100%;

        &:hover {
            text-decoration: none;
        }
    }

    .overzicht-project-tile-wrap {
        height: 100%;

        .image {
            overflow: hidden;
            position: relative;
            align-self: stretch;

            &:hover figure {
                transform: scale(1.06);
            }

            figure {
                height: 100%;
                transition: 0.2s;

                img {
                    height: 100%;
                    object-fit: cover;
                }
            }

            .rented {
                position: absolute;
                top: 0;
                left: 0;
                width: 80px;
                height: 80px;

                img {
                    display: block;
                    width: 100%;
                }
            }
        }

        .content {
            min-height: 240px;
            display: flex;
            align-items: center;

            .content-wrap {
                padding: 18px;

                h3 {
                    font-size: 20px;
                }

                p {
                    font-size: 15px;
                    margin-bottom: 12px;
                }

                ul {
                    padding: 0;
                    margin: 24px 0 6px;
                    list-style: none;
                    line-height: 1.2;

                    li {
                        margin-bottom: 8px;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        .item-wrap {
                            display: flex;
                            align-items: center;

                            img {
                                display: block;
                                width: 20px;
                                height: 20px;
                                margin-right: 6px;
                            }

                            .blck {
                                font-weight: 600;
                                color: black;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (--viewport-md-min) {
    .overzicht-project-tile {
        .overzicht-project-tile-wrap {
            display: flex;
            align-items: center;

            .image {
                width: 48%;
            }

            .content {
                width: 52%;
                min-height: 240px;

                ul {
                    margin: 12px 0;
                }
            }
        }
    }
}
