.my-account-menu {
    .my-account-menu-items,
    .my-account-sub-menu-items {
        padding: 0;
        margin: 0;
        list-style-type: none;
    }

    .my-account-sub-menu-items {
        padding-left: 16px;
    }
}

.my-account-menu-item {
    position: relative;

    &.break {
        background-color: #dadada;
        height: 1px;
        margin: 16px 0;
        pointer-events: none;
    }

    &:hover {
        cursor: pointer;
    }

    &.active::after,
    &:hover::after {
        content: '';
        position: absolute;
        z-index: -1;
        background-color: var(--color-green-light);
        border-radius: 5px;
        width: calc(100% + 18px);
        height: calc(100% + 8px);
        left: -9px;
        top: -4px;
    }

    a {
        color: inherit;
        text-decoration: none;
    }

    button {
        font-family: var(--font-family);
        width: 100%;
    }

    .my-account-menu-item-wrap {
        display: flex;
        align-items: center;
        font-size: 16px;
        margin-bottom: 12px;

        .my-account-menu-item-icon {
            display: block;
            padding-right: 8px;
        }
    }
}

.my-account-sub-menu-item {
    position: relative;

    &:hover {
        cursor: pointer;
    }

    &::after {
        content: '';
        position: absolute;
        z-index: -1;
        background-color: var(--color-green-light);
        border-radius: 5px;
        width: calc(100% + 18px);
        height: calc(100% + 8px);
        left: -9px;
        top: -4px;
        opacity: 0;
    }

    &.active::after,
    &:hover::after {
        opacity: 1;
    }

    &.active a .my-account-sub-menu-item-wrap .my-account-sub-menu-item-icon,
    &:hover a .my-account-sub-menu-item-wrap .my-account-sub-menu-item-icon {
        opacity: 1;
    }

    a {
        color: inherit;
        text-decoration: none;
    }

    .my-account-sub-menu-item-wrap {
        display: flex;
        font-size: 16px;
        margin-bottom: 12px;

        .my-account-sub-menu-item-icon {
            display: block;
            padding-right: 8px;
            opacity: 0;
        }
    }
}

