.project-detail-page-title {
    min-height: 200px;
    margin-top: 0;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    .pdpt-background {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    .container {

        .content {
            position: relative;
            z-index: 2;
            margin: 80px 0 80px;

            h1 {
                max-width: 800px;
                color: #fff;
                margin: 0 auto;
                text-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
            }
        }
    }
}

.project-detail-intro {

    .wrap {
        max-width: 800px;
        margin: 0 auto;
    }
}

.project-detail-slider {
    padding: 60px 0;
    background-color: rgb(243, 243, 243);
    overflow: hidden;

    .main-slider {
        padding: 0 15px;

        .carousel-cell {
            height: 280px;
            padding: 0 8px;

            img {
                width: auto;
                height: 100%;
                border-radius: 5px;
            }
        }
    }

    .flickity-prev-next-button {
        display: block;
        background: rgba(255, 255, 255, 0.8);

        .arrow {
            fill: #62968e;
        }

        &.no-svg {
            fill: #62968e;
        }

        &:hover {
            background: rgba(255, 255, 255, 0.9);
        }

        &.previous {
            left: 25px;
        }

        &.next{
            right: 25px;
        }

        .flickity-button-icon {
            left: 25%;
            width: 50%;
        }
    }

    .flickity-page-dots {
        bottom: -40px;
    }

    .flickity-viewport {
        transition: height .4s;
    }
}

.project-detail-woningen {

    .pd-title {
        text-align: center;
        margin-bottom: 30px;
    }

    .pd-woning-tile {
        padding-bottom: 50px;
        margin-bottom: 50px;
        border-bottom: 1px solid #cfcfcf;

        .wrap {
            display: flex;
            flex-direction: column;
            align-items: center;

            .pd-wt-image {
                width: 100%;
                margin-bottom: 40px;

                .pd-wt-slider {

                    img {
                        border-radius: 5px;
                    }

                    .carousel-cell {
                        width: 100%;
                        padding: 0;
                    }

                    .flickity-prev-next-button {
                        display: block;
                        background: rgba(255, 255, 255, 0.8);

                        .arrow {
                            fill: #62968e;
                        }

                        &.no-svg {
                            fill: #62968e;
                        }

                        &:hover {
                            background: rgba(255, 255, 255, 0.9);
                        }

                        .flickity-button-icon {
                            left: 25%;
                            width: 50%;
                        }
                    }

                    .flickity-viewport {
                        transition: height .4s;
                    }
                }
            }

            .pd-wt-content {
                width: 100%;
                padding-left: 0;

                h3 {

                    .wt-title {
                        display: inline-block;
                        margin-right: 8px;
                    }

                    .wt-label {
                        font-size: 14px;
                        color: #fff;
                        font-weight: 400;
                        display: inline-block;
                        padding: 3px 8px;
                        border-radius: 100px;

                        &--beschikbaar {
                            background-color: #62968e;
                        }

                        &--verkocht {
                            background-color: #e05858;
                        }
                    }
                }

                table {
                    /* width: 100%; */
                    margin-bottom: 20px;

                    th {
                        text-align: left;
                    }

                    td {
                        padding-right: 16px;
                    }
                }
            }
        }
    }
}

.project-detail-cta {
    min-height: 200px;
    margin-top: 0;
    margin-bottom: 0;
    background-image: url('/dist/images/stock-img.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    .overlay {
        background-color: #000;
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 1;
        left: 0;
        right: 0;
        top: 0;
        opacity: 0.6;
    }

    .container {

        .content {
            position: relative;
            z-index: 2;
            margin: 80px auto 80px;
            max-width: 800px;

            h2 {
                font-size: 1.7em;
                color: #fff;
            }
        }
    }
}

.project-map {
    margin: 0;

    iframe {
        display: block;
    }
}

@media (--viewport-md-min) {
    .project-detail-page-title {
        min-height: 500px;

        .container {

            .content {
                margin: 120px 0;

                h1 {
                    font-size: 3.2em;
                }
            }
        }
    }

    .project-detail-slider {
        padding: 80px 0;

        .main-slider {
            padding: 0 25px;

            .carousel-cell {
                height: 480px;
            }
        }

        .flickity-prev-next-button {
            width: 50px;
            height: 50px;

            &:hover {
                background: rgba(255, 255, 255, 0.9);
            }

            &.previous {
                left: 45px;
            }

            &.next{
                right: 45px;
            }
        }
    }

    .project-detail-woningen {

        .pd-woning-tile {

            .wrap {
                flex-direction: row;

                .pd-wt-image {
                    width: 480px;

                    .pd-wt-slider {

                        .carousel-cell {
                            padding: 0 5px;
                        }
                    }
                }

                .pd-wt-content {
                    width: calc(100% - 400px);
                    padding-left: 50px;
                }
            }
        }
    }

    .project-detail-cta {
        min-height: 300px;

        .container {

            .content {

                h2 {
                    font-size: 2.5em;
                }
            }
        }
    }
}