.home-projecten {
    .home-projecten-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 16px;

        .title {
            h2 {
                font-size: 20px;
            }
        }

        .link-more {
            font-size: 14px;
            font-weight: 700;

            a {
                text-decoration: none;
                color: var(--color-green-dark);

                &:hover {
                    color: #406963;
                }
            }
        }
    }

    .home-projecten-wrap {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .home-project-tile {
            width: 100%;
        }
    }
}

@media (--viewport-sm-min) {
    .home-projecten {
        .home-projecten-header {
            margin-bottom: 20px;

            .title {
                h2 {
                    font-size: 28px;
                }
            }
        }

        .home-projecten-wrap {
            margin: 0 -10px;

            .home-project-tile {
                width: calc(100% / 2);
                padding: 0 10px;
            }
        }
    }
}
