.call-to-action {
    position: relative;
    padding: 40px 20px;

    &.cta-color {
        background-color: var(--color-green-dark);
        margin-left: 20px;
        margin-right: 20px;
        border-radius: 10px;
    }

    &.cta-image {
        margin: 0;
        background-color: #000;

        .background-image {
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            opacity: .5;

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
    }

    .call-to-action-wrap {
        position: relative;
        z-index: 2;

        .content {
            max-width: 720px;
            margin: 0 auto;
            color: #fff;
            text-align: center;

            h2 {
                color: #fff;
            }

            .button {
                margin: 12px;
            }
        }
    }
}

@media (--viewport-md-min) {
    .call-to-action {
        padding: 80px 40px;

        &.cta-color {
            margin-left: 40px;
            margin-right: 40px;
        }

        .call-to-action-wrap {
            display: flex;
            align-items: center;

            .content {

                h2 {
                    font-size: 32px;
                }
            }
        }
    }
}
