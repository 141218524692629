.center-modules {
    .center-modules-wrap {
        max-width: 760px;
        margin: 0 auto;
    }
}

.page-content {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;

    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6,
    & p,
    & blockquote,
    & ul,
    & ol {
        width: 100%;
        max-width: 730px;
    }

    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
        margin: 46px auto 16px;
    }

    & p,
    & blockquote,
    & ul,
    & ol {
        margin: 16px auto;
    }

    & ul,
    & ol {

        li {
            margin-left: 32px;
        }
    }

    & img {
        display: block;
        width: 100%;
        height: auto;
        margin: 40px auto;
        /* border: 2px solid #000; */
    }

    & table {
        margin: 2rem auto;

        & td {
            padding: 12px;
        }
    }

    & blockquote {
        margin: 2rem auto;
        padding: 12px 12px 12px 40px;
        border-left: 2px solid var(--color-ocean);
    }

    & .text-big {
        font-size: 1.2em;
    }

    & .pre-head {
        text-transform: uppercase;
        font-size: 0.9em;
        letter-spacing: 1.2px;
    }

    & .quote-box {
        width: 100%;
        height: auto;
        margin: 40px auto;
        background-color: #f4f4f4;
        padding: 30px 50px;

        & h2 {
            margin-top: 16px;
        }
    }

    .spacer {
        height: 24px;
    }
}

@media (--viewport-md-min) {
    .page-content {

        & img {
            position: relative;
            width: calc(100% + 130px);
            margin: 80px auto;
            margin-left: -65px;
        }

        .spacer {
            height: 46px;
        }
    }
}

@media (--viewport-lg-min) {
    .page-content {

        & img {
            width: calc(100% + 260px);
            margin: 80px auto;
            margin-left: -130px;
        }

        & .quote-box {
            width: calc(100% + 260px);
            margin: 80px auto;
            margin-left: -130px;
            padding: 40px 60px;

            & h2,
            & p {
                max-width: unset;
            }
        }

        .spacer {
            height: 60px;
        }
    }
}
