.header-menu-overlay {
    position: fixed;
    z-index: 208;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: #ecf5f100;
    color: #000;
    transform: translateY(-100%);
    overflow: auto;

    .close-button {
        position: fixed;
        z-index: 10;
        top: 20px;
        right: 20px;
        width: 30px;
        height: 30px;
        cursor: pointer;

        .close-button-wrap {
            position: relative;

            span {
                position: absolute;
                content: '';
                display: block;
                background-color: #000;
                width: 26px;
                height: 3px;
                top: 14px;
                left: 2px;
            }

            .aa {
                transform: rotate(45deg);
            }

            .bb {
                transform: rotate(-45deg);
            }
        }
    }

    .header-align-center {
        display: flex;
        min-height: 100%;
        align-items: center;
    }

    &.menu-open {
        animation: menu-animation-wrapper both 300ms;

        .wrap .animation-wrap {
            animation: menu-animation-links both 300ms;
        }
    }

    &.menu-closed {
        animation: menu-closed-animation-wrapper both 250ms reverse;

        .wrap {
                animation: menu-closed-animation-links-fix both 250ms reverse;

            .animation-wrap {
                animation: menu-closed-animation-links both 250ms reverse;
            }
        }
    }

    a {
        display: inline-block;
        color: inherit;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    .wrap {
        position: relative;
        z-index: 2;
        padding: 110px 0 130px;
        text-align: center;

        .animation-wrap {

            .logo {

                a {
                    /* display: block; */
                }

                img {
                    display: block;
                    max-width: 220px;
                    width: 100%;
                }
            }

            .links {
                margin: 2em 0;

                ul {
                    list-style-type: none;
                    padding: 0;
                    margin: 0;
                    font-weight: 700;
                    font-size: 1.5em;
                    line-height: 1.3;
                    font-family: var(--heading-font-family);

                    li {
                        padding: 0;
                    }
                }
            }

            .menu-buttons {
                margin: 2em 0;

                .button {
                    color: #fff;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }

            .menu-socials {
                margin: 2em 0;

                ul {
                    list-style-type: none;
                    padding: 0;
                    margin: 0;

                    li {
                        padding: 0;
                        display: inline-block;
                        font-size: 24px;
                        margin: 0 2px;

                        &:hover {
                            color: #61968e;
                        }
                    }
                }
            }
        }
    }
}

@media (--viewport-md-min) {
    .header-menu-overlay {

        .wrap {
            padding: 120px 0;

            .animation-wrap {

                .links {

                    ul {
                        font-size: 1.9em;
                    }
                }
            }
        }
    }
}

/* Animations */
/* Menu open */
@keyframes menu-animation-wrapper {
    0%   {
        transform: translateY(0);
    }
    70%   {
        background-color: #ecf5f1;
    }
    100% {
        transform: translateY(0);
        background-color: #ecf5f1;
    }
}

@keyframes menu-animation-links {
    0%   {
        opacity: 0;
    }
    50% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

/* Menu closed */
@keyframes menu-closed-animation-wrapper {
    0%   {
        transform: translateY(100%);
    }
    1% {
        transform: translateY(0);
    }
    50% {
        background-color: #ecf5f1;
    }
    100% {
        transform: translateY(0);
        background-color: #ecf5f1;
    }
}

@keyframes menu-closed-animation-links-fix {
    0%   {
        overflow: hidden;
    }
    100% {
        overflow: hidden;
    }
}

@keyframes menu-closed-animation-links {
    0%   {
        opacity: 0;
    }
    50% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}