.my-account-woning-tile {
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    margin-bottom: 16px;

    a {
        color: inherit;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    .my-account-woning-title {
        background-color: var(--color-green-light);
        padding: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .adress {

            a:hover {
                text-decoration: underline;
            }

            h2 {
                margin: 0;
                font-size: 18px;
                padding-left: 3px;
            }

            .zip {
                display: flex;
                align-items: center;
                line-height: 1.6;

                img {
                    display: block;
                    width: 26px;
                    padding-right: 6px;
                }
            }
        }

        .interest {
            cursor: pointer;
            transform: scale(1, 1);

            svg {
                display: block;

                path {
                    fill: transparent;
                    stroke: #6f6f6f;
                    stroke-width: 2;
                }
            }

            &.interested {
                svg path {
                    fill: var(--color-red);
                    stroke: var(--color-red);
                }
            }

            &:focus {
                animation: beat 0.6s ease-in-out backwards;
            }

            &:active {
                animation: none;
            }

            @keyframes beat {
                0% {
                    transform: scale(1, 1);
                }

                33% {
                    transform: scale(0.85, 0.85);
                }

                66% {
                    transform: scale(1.2, 1.2);
                }

                100% {
                    transform: scale(1, 1);
                }
            }
        }
    }

    .my-account-woning-tile-wrap {
        display: flex;
        align-items: center;

        &:hover .image figure {
            transform: scale(1.06);
        }

        .image {
            overflow: hidden;
            position: relative;
            align-self: stretch;
            width: 35%;

            figure {
                height: 100%;
                transition: 0.2s;

                img {
                    height: 100%;
                    object-fit: cover;
                }
            }

            .rented {
                position: absolute;
                top: 0;
                left: 0;
                width: 80px;
                height: 80px;

                img {
                    display: block;
                    width: 100%;
                }
            }
        }

        .content {
            width: 65%;
            min-height: 80px;
            display: flex;
            align-items: center;

            ul {
                padding: 0 0 0 24px;
                margin: 16px 0;
                list-style: none;

                li {
                    margin-bottom: 6px;
                }
            }
        }
    }
}

@media (--viewport-md-min) {
    .my-account-woning-tile {
        .my-account-woning-tile-wrap {
            .content {
                min-height: 144px;

                ul {
                    margin: 32px 0;
                }
            }
        }
    }
}
