.image-text {
    .image-text-wrap {
        .content {
            align-self: center;
            width: 100%;
            padding: 0;
        }

        .image {
            align-self: center;
            width: 100%;

            img {
                border-radius: 5px;
            }
        }
    }
}

@media (--viewport-md-min) {
    .image-text {
        &.reverse .image-text-wrap {
            flex-direction: row-reverse;

            .content {
                padding: 60px 0 60px 60px;
            }
        }

        .image-text-wrap {
            display: flex;
            flex-direction: row;

            .content {
                width: 50%;
                padding: 60px 60px 60px 0;
            }

            .image {
                width: 50%;
            }
        }
    }
}
