.create-account {
    position: relative;

    &::before {
        content: '';
        position: absolute;
        z-index: 1;
        background-color: var(--color-green-light);
        top: -80px;
        left: 0;
        width: 100%;
        height: 240px;
    }

    .create-account-wrap {
        position: relative;
        z-index: 2;
        max-width: 580px;
        margin: 0 auto;

        h1 {
            margin-bottom: 30px;
            text-align: center;
        }

        .form-fields {
            background-color: #fff;
            box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
            border-radius: 5px;
            padding: 36px 24px;

            h2 {
                margin-bottom: 16px;
            }

            a {
                font-weight: 700;
                text-decoration: none;
                color: var(--color-green-dark);

                &:hover {
                    color: #406963;
                }
            }

            .forgot-password {
                margin-top: 20px;
            }

            .got-an-account {
                margin-top: 30px;
                padding-top: 20px;
                border-top: 1px solid #efefef;
            }
        }
    }
}
