.button {
    background-color: var(--color-green-dark);
    color: #fff;
    padding: 8px 14px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    transition: 0.2s;
    border: 2px solid var(--color-green-dark);
    border-radius: 5px;
    margin: 8px 12px 8px 0;
    line-height: 1.2;
    font-weight: 600;

    &:hover {
        background-color: #406963;
        color: #fff;
        border: 2px solid #406963;
    }

    &.alt {
        background-color: var(--color-red);
        color: #fff;
        border: 2px solid var(--color-red);

        &:hover {
            background-color: #b32525;
            color: #fff;
            border: 2px solid #b32525;
        }
    }

    &.border {
        background-color: transparent;
        color: var(--color-text);
        border: 2px solid var(--color-green-dark);

        &:hover {
            background-color: #406963;
            color: #fff;
            border: 2px solid #406963;
        }
    }

    &.disabled {
        cursor: not-allowed;
        background-color: #c5c5c5;
        border: 2px solid #c5c5c5;
        color: #000;

        &:hover {
            background-color: #c5c5c5;
            border: 2px solid #c5c5c5;
            color: #000;
        }
    }
}
